import React from 'react';
import { md, Node } from 'djedi-react';

const pageName = 'Timeslots';

const NODES = {
  META: {
    TITLE: (
      <Node
        uri={`${pageName}/meta/title`}
        city="Oslo"
        date="i dag"
      >{`Første ledige time i {city} fra {dateFrom}`}</Node>
    ),
    DESCRIPTION: (
      <Node uri={`${pageName}/meta/description`}>Forstå NIPT og gjør valg som er rett for deg</Node>
    ),
    CLINIC: {
      TITLE: (
        <Node
          uri={`${pageName}/meta/clinic/title`}
          name="Klinikk"
        >{`Første ledige time hos {name}`}</Node>
      ),
    },
    BOOKING: {
      TITLE: (
        <Node
          uri={`${pageName}/meta/booking/title`}
          name="Klinikk"
          timeslot="lørdag 15. januar kl 14-15"
        >{`Book tid hos {name} for {date}, {timeStart} - {timeEnd}`}</Node>
      ),
    },
  },
  TITLE: <Node uri={`${pageName}/title`}>Første ledige time</Node>,
  DAYTIME: (
    <Node uri={`${pageName}/daytime`} key="dagtid">
      dagtid
    </Node>
  ),
  EVENING_TIME: (
    <Node uri={`${pageName}/evening-time`} key="evening-time">
      kveldstid
    </Node>
  ),
  WEEKEND: (
    <Node uri={`${pageName}/weekend`} key="weekend">
      helg
    </Node>
  ),
  WHENEVER: (
    <Node uri={`${pageName}/whenever`} key="whenever">
      når som helst
    </Node>
  ),
  SUBMIT_SEARCH: <Node uri={`${pageName}/submit-search`}>Finn en klinikk</Node>,
  SUBMIT_SEARCH_EDIT: <Node uri={`${pageName}/submit-search-edit`}>Endre</Node>,
  INVALID_DEFAULT_CITY: (
    <Node uri={`${pageName}/invalid-default-city`}>Standardbyalternativet er ikke gyldig.</Node>
  ),
  DEFAULT_CITY: <Node uri={`${pageName}/default-city`}>Velg by</Node>,
  NONE_FOUND: <Node uri={`${pageName}/none-found`}>Ingen tidsluker ble funnet</Node>,
  LOADING: <Node uri={`${pageName}/loading`}>Henter tilgjengelige tider</Node>,
  ALL_TIMESLOTS: <Node uri={`${pageName}/all-timeslots`}>Alle tider</Node>,
  NO_TIMESLOTS: (
    <Node uri={`${pageName}/no-timeslots`}>Ingen time ble funnet, prøv å endre datoe</Node>
  ),
  FREE_SERVICE: <Node uri={`${pageName}/free-service`}>Kostnadsfritt</Node>,
  FETCH_MORE_TIMESLOTS: <Node uri={`${pageName}/fetch-more-timeslots`}>Hente fler timer</Node>,
  ABOUT_CLINIC: <Node uri={`${pageName}/about-clinic`}>Om klinikken</Node>,
  COST: <Node uri={`${pageName}/cost`}>Kostnad</Node>,
  BOOKING: {
    TITLE: <Node uri={`${pageName}/booking/title`}>Bekrefte reservasjon</Node>,
    MARKET: <Node uri={`${pageName}/booking/market`}>Tjenst</Node>,
    CTA: <Node uri={`${pageName}/booking/cta`}>Book tid direkt</Node>,
    CLINIC: <Node uri={`${pageName}/booking/clinic`}>Klinikk</Node>,
    SELECTED_TIMESLOT: <Node uri={`${pageName}/booking/selected-timeslot`}>Valgt tid og dag</Node>,
    COST_NOTE: <Node uri={`${pageName}/booking/cost-note`}> (betales hos klinikken)</Node>,
    REASONABLE_COST: <Node uri={`${pageName}/booking/reasonable-cost`}>Rettferdig</Node>,
    FROM_PRICE_PREFIX: <Node uri={`${pageName}/booking/from-price-prefix`}>fra </Node>,
    CONTACT_INFORMATION: (
      <Node uri={`${pageName}/booking/contact-information`}>Din kontaktinformation</Node>
    ),
    COMMENT_TITLE: (
      <Node uri={`${pageName}/booking/comment-title`}>
        Har du noe du vil informere klinikken om?
      </Node>
    ),
    COMMENT_PLACEHOLDER: <Node uri={`${pageName}/booking/comment-placeholder`}>Skriv her...</Node>,
    PREGNANCY_WEEK: (
      <Node uri={`${pageName}/booking/pregnancy-week`}>Hvilken graviditetsuke er du i ?</Node>
    ),
    // -- field placeholders
    // should Input component should be modified to append asterix
    // to placeholder if required? (would affect other parts of the site)
    FIRST_NAME: <Node uri={`${pageName}/booking/first-name`}>Fornavn *</Node>,
    LAST_NAME: <Node uri={`${pageName}/booking/last-name`}>Efternavn *</Node>,
    EMAIL: <Node uri={`${pageName}/booking/email`}>E-postadresse *</Node>,
    PHONE: <Node uri={`${pageName}/booking/phone`}>Mobilnummer *</Node>,
    SSN: <Node uri={`${pageName}/booking/ssn`}>Personnummer ex. 250591-12445 *</Node>,
    WEEK: <Node uri={`${pageName}/booking/week`}>Skriv uke *</Node>,
    DAY: <Node uri={`${pageName}/booking/day`}>Skriv dag *</Node>,
    // --
    SUBMIT: <Node uri={`${pageName}/booking/submit`}>Book tid</Node>,
    SUBMIT_INFO: (
      <Node uri={`${pageName}/booking/submit-info`}>
        Du får bestillingsbekreftelse på SMS og Email.
      </Node>
    ),
    SUMMARY: {
      TITLE: <Node uri={`${pageName}/booking/summary/title`}>Takk for din bestilling!</Node>,
      DESCRIPTION: (
        <Node uri={`${pageName}/booking/summary/description.md`}>{md`
Innen 24 timer vil klinikken bekrefte bestillingen. Hvis timen din av en eller annen grunn må endres, vil du bli kontaktet av klinikken på telefon for å bestille en ny tid.
        `}</Node>
      ),
    },
    ERRORS: {
      ALREADY_BOOKED: (
        <Node uri={`${pageName}/booking/errors/already-booked`}>
          Tiden du valgte er dessverre ikke lenger tilgjengelig. Velg en annen tid.
        </Node>
      ),
    },
    THANK_YOU: {
      TITLE: <Node uri={`${pageName}/booking/thank-you/title`}>Takk for din bestilling!</Node>,
      DESCRIPTION: (
        <Node uri={`${pageName}/booking/thank-you/description`}>
          Innen 24 timer vil klinikken bekrefte bestillingen. Hvis timen din av en eller annen grunn
          må endres, vil du bli kontaktet av klinikken på telefon for å bestille en ny tid.
        </Node>
      ),
      DETAILS_TITLE: (
        <Node uri={`${pageName}/booking/thank-you/details-title`}>Din bestilling</Node>
      ),
      CTA: <Node uri={`${pageName}/booking/thank-you/cta`}>Tilbake til forsiden</Node>,
    },
    INFO: {
      TITLE: <Node uri={`${pageName}/booking/info/title`}>Gjennomgå og bekreft bestillingen</Node>,
      SELECTED_SLOT: {
        TITLE: <Node uri={`${pageName}/booking/info/selected-slot/title`}>Valgt tid och dag</Node>,
        CHANGE: <Node uri={`${pageName}/booking/info/selected-slot/change`}>Endra tid</Node>,
      },
      CONFIRMATION: (
        <Node uri={`${pageName}/booking/confirmation.md`}>{md`
### Bekreftelse på bestilling <br />

Innen 24 timer vil klinikken bekrefte bestillingen. Hvis timen din av en eller annen grunn må endres, vil du bli kontaktet av klinikken på telefon for å bestille en ny tid.
        `}</Node>
      ),
      CTA_HEADING: <Node uri={`${pageName}/cta/heading`}>Informerte valg</Node>,
      CTA_DESCRIPTION: (
        <Node uri={`${pageName}/cta/description`}>
          Du har rett på informasjon før du gjør NIPT. Du kan gjøre NIPT- skolen når du vil. Den er
          helt gratis. Du logger trygt inn med din BankID.
        </Node>
      ),
    },
  },
};

export default NODES;
