import React from 'react';
import { spacing } from 'utils';

import styles from './Spacing.module.css';

export interface SpacingProps {
  component?: React.ElementType;
  size?: number;
  divider?: boolean;
}

const Spacing: React.FC<SpacingProps & Partial<HTMLElement>> = ({
  component: Component = 'div',
  size = 1,
  divider = false,
  ...rest
}) => {
  return (
    <Component {...rest} style={{ padding: spacing(size / 2) }}>
      <Component className={divider && styles.divider} />
    </Component>
  );
};

export default Spacing;
