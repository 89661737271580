import { useMemo } from 'react';
import moment from 'moment';

/**
 * @returns A {@link moment.CalendarSpec} to be used with {@link moment.Moment.calendar} for
 * formatting a date without time, i.e. returning "today", "tomorrow", "June 7th 2022"
 * instead of "today at 5 PM" or "June 7th 2022 at 5 PM".
 *
 * @remarks
 * It currently only works for languages that prefix time with "kl." or not at all.
 * For instance, in German, time is prefixed with "um", so German is not supported.
 * *(At the time of writing, possible languages are just Swedish and Norwegian, so this fine for now.)*
 *
 * If we want to use a more scalable solution, we could just do `moment.format("dddd MMMM DD")`
 * to get dates like "tuesday June 7th", but in that case we won't get "today" and "tomorrow", which
 * is quite important in some cases.
 */
export function useMomentCalendarDateSpec() {
  const locale = moment.locale();

  return useMemo<moment.CalendarSpec>(() => {
    const localeData = moment.localeData();

    const timeSubstrings = [' LT', ' kl.', ' at'];
    const removeTime = (value: string) => {
      timeSubstrings.forEach(remove => (value = value.split(remove).join('')));
      return value;
    };

    return {
      sameDay: removeTime(localeData.calendar('sameDay')),
      nextDay: removeTime(localeData.calendar('nextDay')),
      lastDay: removeTime(localeData.calendar('lastDay')),
      lastWeek: removeTime(localeData.calendar('lastWeek')),
      nextWeek: 'dddd D MMMM',
      sameElse: 'dddd D MMMM',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
}
